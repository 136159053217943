
import Image from 'next/image';
import Link from 'next/link';
import SvgRenderer from '../Common/SvgRenderer';
import {
  ChangeLogRecord,
  FooterQuery,
  LegalPageRecord,
  SiteLocale,
} from '@/graphql/types/graphql';
import { notFound } from 'next/navigation';
import { primaryColor } from '@/app/i18n/settings';
import ReactMarkdown from 'react-markdown';

type Props = {
  data: FooterQuery;
  lng: SiteLocale;
};

const Footer = ({ data, lng }: Props) => {
  return (
    <footer className="relative z-10 mx-auto flex w-full flex-col items-center justify-center bg-primary bg-opacity-5 pt-16 text-center md:text-start lg:pt-24">
      <div className="container w-full">
        <div className="flex w-full flex-col justify-between md:flex-row md:px-16">
          <div className="w-full">
            <div className="mx- mb-12 lg:mb-16">
              <Link href={'/' + lng + '/home'} className="mb-8 inline-block">
                {data.layout?.footerLogo && (
                  <Image
                    src={data.layout.footerLogo.url}
                    alt="logo"
                    className="w-full"
                    width={data.layout.footerLogo.width || 100}
                    height={data.layout.footerLogo.height || 100}
                  />
                )}
              </Link>
              <div className="mb-9 text-base font-medium leading-relaxed text-body-color">
                <ReactMarkdown>
                  {data.layout!.footerSubtitle || ''}
                </ReactMarkdown>
              </div>
              <div className="flex items-center justify-center md:justify-start">
                {data.layout!.socialMediaLinks.map((socialMedia) => {
                  return (
                    <a
                      href={socialMedia.url}
                      aria-label="social-link"
                      className="mr-6 text-[#CED3F6] hover:text-primary"
                      key={socialMedia.id}
                    >
                      <SvgRenderer url={socialMedia.icon.url} />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="flex w-full md:text-end">
            <div className="w-full">
              <div className="mb-12 lg:mb-16">
                <h2 className="mb-10 text-xl font-bold text-black dark:text-white">
                  Legal
                </h2>
                <ul>
                  {data.layout!.footerLinks.map((link) => {
                    const pageLink = link as LegalPageRecord; // The field has a "at least one" validation
                    return (
                      <li key={pageLink.id}>
                        <a
                          href={'/' + lng + '/legal/' + pageLink.slug}
                          className="mb-4 inline-block text-base font-medium text-body-color hover:text-primary"
                        >
                          {' '}
                          {pageLink.title}{' '}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SVG and other content above this line */}
      <div className="absolute right-0 top-14 z-[-1]">
        <svg
          width="55"
          height="99"
          viewBox="0 0 55 99"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* SVG content */}
        </svg>
      </div>

      <div className="absolute bottom-24 left-0 z-[-1]">
        <svg
          width="79"
          height="94"
          viewBox="0 0 79 94"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* SVG content */}
        </svg>
      </div>

      {/* Credit section positioned at the bottom-left */}
      <div
  style={{
    position: 'absolute',
    bottom: '10px',
    left: '10px',
  }}
  dangerouslySetInnerHTML={{
    __html: `
    <p style="font-size: 10px; color: grey; text-align: left; font-family: Arial, sans-serif; background: linear-gradient(90deg, #2A2A2A, #F84D1D, #B3BCB9, #F47234, #EAE9E6, #2A2A2A, #C1C1C1); -webkit-background-clip: text; -webkit-text-fill-color: transparent; transition: background 0.3s ease, -webkit-text-fill-color 0.3s ease;" 
 onmouseover="this.style.webkitTextFillColor='inherit'; this.style.background='linear-gradient(90deg, #C1C1C1, #2A2A2A, #EAE9E6, #F47234, #B3BCB9, #F84D1D, #2A2A2A)';"
   onmouseout="this.style.webkitTextFillColor='transparent'; this.style.background='linear-gradient(90deg, #2A2A2A, #F84D1D, #B3BCB9, #F47234, #EAE9E6, #2A2A2A, #C1C1C1)';">Webdesign</a>, 
    <a href="https://www.simondodson.com" style="color: inherit; text-decoration: none;">Digital Strategy</a>, 
    <a href="https://www.simondodson.com" style="color: inherit; text-decoration: none;">Content</a> by 
    <a href="https://www.simondodson.com" style="color: gray; text-decoration: ;">Digital Consultant Simon Dodson</a>
</p> 
      <style>
        .hover-link:hover {
            color: #F84D1D; /* Change color on hover */
            text-decoration: underline; /* Underline on hover */
        }
      </style>
    `,
  }}
/>
    </footer>
  );
};

export default Footer;
